<script setup lang="ts">
defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
})

defineSlice({
  preview: 'SlicesTwentyFifthAnniversarySquareGallery.jpg',
  description: 'Square Gallery',
  fields: {
    bigOnRight: { label: 'Big Image on Right?', type: 'checkbox' },
    bigImage: { label: 'Big Image', type: 'media', breakpoints: { lg: { width: 775, height: 900 } } },
    bigImageCaption: { label: 'Big Image Caption', type: 'text' },
    bigImageLink: { label: 'Big Image Link', type: 'link', enabled: false },
    smallImage1: { label: 'Small Image 1', type: 'media', breakpoints: { lg: { width: 385, height: 450 } } },
    smallImage1Caption: { label: 'Small Image 1 Caption', type: 'text' },
    smallImage1Link: { label: 'Small Image 1 Link', type: 'link', enabled: false },
    smallImage2: { label: 'Small Image 2', type: 'media', breakpoints: { lg: { width: 385, height: 450 } } },
    smallImage2Caption: { label: 'Small Image 2 Caption', type: 'text' },
    smallImage2Link: { label: 'Small Image 2 Link', type: 'link', enabled: false },
    smallImage3: { label: 'Small Image 3', type: 'media', breakpoints: { lg: { width: 385, height: 450 } } },
    smallImage3Caption: { label: 'Small Image 3 Caption', type: 'text' },
    smallImage3Link: { label: 'Small Image 3 Link', type: 'link', enabled: false },
    smallImage4: { label: 'Small Image 4', type: 'media', breakpoints: { lg: { width: 385, height: 450 } } },
    smallImage4Caption: { label: 'Small Image 4 Caption', type: 'text' },
    smallImage4Link: { label: 'Small Image 4 Link', type: 'link', enabled: false },
  },
  name: { label: 'Square Gallery', group: '25th Anniversary' },
  templates: [{
    label: 'Square Gallery',
    fields: {
      bigImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-aquaventure-power-tower-the-surge-waterslide.jpg' },
      bigImageCaption: { value: 'aquaventure thrills' },
      smallImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Dining\/Fine\/Silan\/atlantis-fine-dining-silan-assortment-of-menu-items-1.jpg' },
      smallImage1Caption: { value: 'signature dining' },
      smallImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/atlantis-marine-programs-dolphin-cay-rise-and-shine.jpeg' },
      smallImage2Caption: { value: 'dolphin cay' },
      smallImage3: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/looking-forward\/newsletter\/july\/atlantis-face-painted-kids-bahamian-flag-american-flag-on-heart-swing.jpg' },
      smallImage3Caption: { value: 'Bucket List' },
      smallImage4: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/reef\/Beaches_Hero.jpg' },
      smallImage4Caption: { value: '5 miles of beaches' },
    },
  }],
})

function goToLink(link) {
  if (link.enabled && link.value.href)
    window.open(link.value.href, link.value.target)
}
</script>

<template>
  <div class="mt-1">
    <div class="grid gap-1 lg:grid-cols-4 lg:grid-rows-2">
      <div
        class="relative group lg:col-span-2 row-span-2 bg-faw-light-blue overflow-hidden"
        :class="{
          'lg:col-start-3': fields.bigOnRight.value,
          'cursor-pointer': fields.bigImageLink.enabled,
        }"
        @click="goToLink(fields.bigImageLink)"
      >
        <VoixMedia :field="fields.bigImage" class="object-cover w-full h-full group-hover:scale-105 transition-all duration-500 ease-in-out" />

        <div
          class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/75 to-black/0"
        />
        <div
          class="absolute z-10 text-white text-2xl font-sans2 uppercase font-light bottom-0 left-0 right-0 p-6"
        >
          <a
            v-if="fields.bigImageLink.enabled"
            :href="fields.bigImageLink.href"
            :target="fields.bigImageLink.target"
          >{{ fields.bigImageCaption.text }}</a>
          <span v-else>{{ fields.bigImageCaption.text }}</span>
        </div>
      </div>
      <div
        class="relative group overflow-hidden bg-faw-light-blue"
        :class="{
          'lg:row-start-1': fields.bigOnRight.value,
          'cursor-pointer': fields.smallImage1Link.enabled,
        }"
        @click="goToLink(fields.smallImage1Link)"
      >
        <VoixMedia :field="fields.smallImage1" class="object-cover w-full h-full group-hover:scale-105 transition-all duration-500 ease-in-out" />

        <div
          v-if="fields.smallImage1.value?.[0]?.url"
          class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/75 to-black/0"
        />
        <div
          class="absolute z-10 text-white text-2xl font-sans2 uppercase font-light bottom-0 left-0 right-0 p-6"
        >
          <a
            v-if="fields.smallImage1Link.enabled"
            :href="fields.smallImage1Link.href"
            :target="fields.smallImage1Link.target"
          >{{ fields.smallImage1Caption.text }}</a>
          <span v-else> {{ fields.smallImage1Caption.text }}</span>
        </div>
      </div>
      <div
        class="relative group overflow-hidden bg-faw-light-blue"
        :class="{
          'lg:row-start-1': fields.bigOnRight.value,
          'cursor-pointer': fields.smallImage2Link.enabled,
        }"
        @click="goToLink(fields.smallImage2Link)"
      >
        <VoixMedia :field="fields.smallImage2" class="object-cover w-full h-full group-hover:scale-105 transition-all duration-500 ease-in-out" />

        <div
          v-if="fields.smallImage2.value?.[0]?.url"
          class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/75 to-black/0"
        />
        <div
          class="absolute z-10 text-white text-2xl font-sans2 uppercase font-light bottom-0 left-0 right-0 p-6"
        >
          <a
            v-if="fields.smallImage2Link.enabled"
            :href="fields.smallImage2Link.href"
            :target="fields.smallImage2Link.target"
          >{{ fields.smallImage2Caption.text }}</a>
          <span v-else> {{ fields.smallImage2Caption.text }}</span>
        </div>
      </div>
      <div
        class="relative group overflow-hidden bg-faw-light-blue"
        :class="{ 'cursor-pointer': fields.smallImage3Link.enabled }"
        @click="goToLink(fields.smallImage3Link)"
      >
        <VoixMedia :field="fields.smallImage3" class="object-cover w-full h-full group-hover:scale-105 transition-all duration-500 ease-in-out" />

        <div
          v-if="fields.smallImage3.value?.[0]?.url"
          class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/75 to-black/0"
        />
        <div
          class="absolute z-10 text-white text-2xl font-sans2 uppercase font-light bottom-0 left-0 right-0 p-6"
        >
          <a
            v-if="fields.smallImage3Link.enabled"
            :href="fields.smallImage3Link.href"
            :target="fields.smallImage3Link.target"
          >{{ fields.smallImage3Caption.text }}</a>
          <span v-else> {{ fields.smallImage3Caption.text }}</span>
        </div>
      </div>
      <div
        class="relative group overflow-hidden bg-faw-light-blue"
        :class="{ 'cursor-pointer': fields.smallImage4Link.enabled }"
        @click="goToLink(fields.smallImage4Link)"
      >
        <VoixMedia :field="fields.smallImage4" class="object-cover w-full h-full group-hover:scale-105 transition-all duration-500 ease-in-out" />

        <div
          v-if="fields.smallImage4.value?.[0]?.url"
          class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/75 to-black/0"
        />
        <div
          class="absolute z-10 text-white text-2xl font-sans2 uppercase font-light bottom-0 left-0 right-0 p-6"
        >
          <a
            v-if="fields.smallImage4Link.enabled"
            :href="fields.smallImage4Link.href"
            :target="fields.smallImage4Link.target"
          >{{ fields.smallImage4Caption.text }}</a>
          <span v-else> {{ fields.smallImage4Caption.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
